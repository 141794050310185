import {
  forwardRef,
  HTMLAttributes,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import numeral from "numeral";
import { isEqual } from "lodash-es";
import styled from "@emotion/styled/macro";
import { OverlayTrigger, Tooltip, TooltipProps } from "react-bootstrap";

import { Card, TextTitle } from "components";
import { useOrder } from "providers";
import { OrderbookReturn, OrderbookPriceLevel } from "models/WebsocketClients";
import { useMemoCompare } from "hooks";
import { mediaQuery, priceNumber } from "utils";

interface PriceLevelTooltipProps {
  accuAvgPrice: number;
  accuTotalAmount: number;
  accuTotalValue: number;
}

export interface OrderbookPriceLevelWithTotal
  extends OrderbookPriceLevel,
    PriceLevelTooltipProps {
  amount: string; // the amount at this Rate
  price: string; // the rate of all orders on this level
  side: number; // buy or sell
}

const CardView = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: ${(props) => props.theme.baseBackgroundColor};
`;

const Title = styled(TextTitle)`
  font-size: 0.875rem;
  font-weight: 600;
  padding: 8px 0;
  color: ${(props) => props.theme.textColor};
  ${mediaQuery("mobile")} {
    padding: 8px 16px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 60px minmax(200px, 30vh) minmax(200px, 30vh);
  gap: 12px 0px;
  grid-template-areas:
    "Order Order Order"
    "Table1 Table1 Table1"
    "Table2 Table2 Table2";
`;

const OrderDiv = styled.div`
  grid-area: Order;
`;

const Table1 = styled.div`
  grid-area: Table1;
  overflow: auto;
  border-bottom: 1px solid ${(props) => props.theme.white4};
  .orderbook-row:hover {
    background-color: ${(props) => props.theme.black2};
    & ~ .orderbook-row {
      background-color: ${(props) => props.theme.black2};
    }
  }
`;
const Table2 = styled.div`
  grid-area: Table2;
  overflow: auto;
  &:hover .orderbook-row {
    background-color: ${(props) => props.theme.black2};
    &:hover ~ .orderbook-row {
      background-color: transparent;
    }
  }
`;

const Row = styled.div`
  margin: 0;
  padding: 3px 12px;
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid transparent;

  &.row-side-buy {
    &:hover {
      border-bottom: 1px dashed rgb(132, 142, 156);
    }
  }

  &.row-side-sell {
    &:hover {
      border-top: 1px dashed rgb(132, 142, 156);
    }
  }

  .Row-order {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
  }
  .Row-title {
    width: 100%;
    color: #9daac6;
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
  }

  ${mediaQuery("mobile")} {
    padding: 3px 18px;
  }
`;

const Power = styled.div<{ color: string; barSize: string }>`
  border-radius: 4px 0px 0px 3px;
  position: absolute;
  width: ${(props) => props.barSize}%;
  height: calc(100% - 4px);
  opacity: 0.1;
  right: 0;
  background-color: ${(props) => props.color};
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
  BarSize: string;
  Side?: string;
  Price?: string;
  Amount?: string;
  Key?: string;
}

const Item = forwardRef<HTMLDivElement, Props>(
  ({ BarSize, Side, Price, Amount, Key, className, ...rest }, ref) => (
    <Row
      key={Key}
      id={`${Side}-${Price}`}
      className={`orderbook-row row-side-${Side} ${className}`}
      ref={ref}
      {...rest}
    >
      <Power
        color={Side === "sell" ? "#d02828" : "#1fa377"}
        barSize={BarSize}
      />
      <div
        className="Row-order"
        style={{ color: Side === "sell" ? "#d02828" : "#1fa377" }}
      >
        {Price}
      </div>
      <div className="Row-order" style={{ textAlign: "right" }}>
        {Amount}
      </div>
      <div className="Row-order" style={{ textAlign: "right" }}>
        {Price && Amount
          ? numeral(
              (numeral(Price).value() ?? 0) * (numeral(Amount).value() ?? 0)
            ).format("0,0.00")
          : 0}
      </div>
    </Row>
  )
);

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 200px;
`;

const renderTooltip = (
  props: TooltipProps,
  priceLevelProps: PriceLevelTooltipProps
) => (
  <Tooltip id="button-tooltip" {...props}>
    <GridContainer>
      <div>Avg. Price</div>
      <div>{priceNumber(priceLevelProps.accuAvgPrice)}</div>
      <div>Sum Credit</div>
      <div>{priceNumber(priceLevelProps.accuTotalAmount)}</div>
      <div>Sum THB</div>
      <div>{priceNumber(priceLevelProps.accuTotalValue)}</div>
    </GridContainer>
  </Tooltip>
);

const initData = {
  sell: [],
  buy: [],
};

const mockData = {
  sell: [
    {
      price: "1000",
      amount: "100",
      side: 1,
    },
    {
      price: "1200",
      amount: "50",
      side: 1,
    },
    {
      price: "1300",
      amount: "20",
      side: 1,
    },
  ],
  buy: [
    {
      price: "900",
      amount: "100",
      side: 0,
    },
    {
      price: "800",
      amount: "50",
      side: 0,
    },
    {
      price: "700",
      amount: "20",
      side: 0,
    },
  ],
};

const OrderBook = () => {
  const sellTable = useRef() as RefObject<HTMLDivElement>;
  const buyTable = useRef() as RefObject<HTMLDivElement>;
  const [data, setData] = useState<OrderbookReturn>(mockData);

  const finalData = useMemoCompare(data, isEqual);

  const {
    state: { order, isOpen },
  } = useOrder();

  const handleData = (newData: OrderbookReturn) => {
    setData(newData);
  };

  useEffect(() => {
    if (order) {
      setData(initData);
      order.subscribe(handleData);
    }
    return () => {
      if (order) {
        order.unsubscribe();
      }
    };
  }, [order]);

  const { sellWithTotal, buyWithTotal, max } = useMemo(() => {
    if (!finalData) return { sellWithTotal: [], buyWithTotal: [], max: 1 };
    const sell = (finalData?.sell || []) as OrderbookPriceLevel[];
    const buy = (finalData?.buy || []) as OrderbookPriceLevel[];
    // sort by price and limit 20
    const sortedSell = sell
      .sort((a, b) => (+a.price > +b.price ? -1 : 1)) // desc
      .slice(-20);
    // sell total = reduce from the last
    const sellWithTotal = sortedSell.reduceRight((acc, cur) => {
      // acc[0] = lastest item in acc
      const accuTotalAmount = (acc[0]?.accuTotalAmount || 0) + +cur.amount;
      const accuTotalValue =
        (acc[0]?.accuTotalValue || 0) + +cur.amount * +cur.price;
      const accuAvgPrice = accuTotalValue / accuTotalAmount;
      return [
        {
          ...cur,
          accuAvgPrice,
          accuTotalAmount,
          accuTotalValue,
        },
        ...acc,
      ];
    }, [] as OrderbookPriceLevelWithTotal[]);
    const sortedBuy = buy
      .sort((a, b) => (+a.price > +b.price ? -1 : 1)) // desc
      .slice(0, 20);
    const buyWithTotal = sortedBuy.reduce((acc, cur, index) => {
      const accuTotalAmount =
        (acc[index - 1]?.accuTotalAmount || 0) + +cur.amount;
      const accuTotalValue =
        (acc[index - 1]?.accuTotalValue || 0) + +cur.amount * +cur.price;
      const accuAvgPrice = accuTotalValue / accuTotalAmount;
      return [
        ...acc,
        { ...cur, accuTotalAmount, accuAvgPrice, accuTotalValue },
      ];
    }, [] as OrderbookPriceLevelWithTotal[]);
    // find max of both side (default to 1 to prevent divided by 0)
    const max = Math.max(
      +(sellWithTotal[0]?.accuTotalAmount || 1),
      +(buyWithTotal[buyWithTotal.length - 1]?.accuTotalAmount || 1)
    );
    return { sellWithTotal, buyWithTotal, max };
  }, [finalData]);

  useEffect(() => {
    if (sellTable.current) {
      sellTable.current?.scrollTo({
        top: sellTable.current.scrollHeight - sellTable.current.clientHeight,
      });
    }
  }, [sellWithTotal]);

  useEffect(() => {
    if (buyTable.current) {
      buyTable.current?.scrollTo({ top: 0 });
    }
  }, [buyWithTotal]);

  return (
    <CardView>
      <Container>
        <OrderDiv>
          <Title>Order Book</Title>
          <Row>
            <div className="Row-title">Price(THB)</div>
            <div className="Row-title" style={{ textAlign: "right" }}>
              Amount
            </div>
            <div className="Row-title" style={{ textAlign: "right" }}>
              Total
            </div>
          </Row>
        </OrderDiv>
        <Table1
          ref={sellTable}
          className="orderbook-table sell-table"
          id="sell-table"
        >
          {sellWithTotal.map((sell) => {
            const Price = priceNumber(+sell.price);
            const Amount = priceNumber(+sell.amount, { min: 0, max: 0 });
            const BarSize = // (+sell.total /
              //   sellAccumulate[sellAccumulate.length - 1]) *
              // 100
              priceNumber((+sell.accuTotalAmount / max) * 100);
            return (
              <OverlayTrigger
                placement="auto-start"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderTooltip(props, sell)}
              >
                <Item
                  key={Price + Amount + BarSize}
                  Price={Price}
                  Amount={Amount}
                  BarSize={BarSize}
                  Side="sell"
                />
              </OverlayTrigger>
            );
          })}
        </Table1>
        <Table2
          ref={buyTable}
          className="orderbook-table buy-table"
          id="buy-table"
        >
          {buyWithTotal.map((buy) => {
            const Price = priceNumber(+buy.price);
            const Amount = priceNumber(+buy.amount, { min: 0, max: 0 });
            const BarSize = // (+buy.total /
              //   buyAccumulate[buyAccumulate.length - 1]) *
              // 100
              priceNumber((+buy.accuTotalAmount / max) * 100);
            return (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => renderTooltip(props, buy)}
              >
                <Item
                  key={Price + Amount + BarSize}
                  Price={Price}
                  Amount={Amount}
                  BarSize={BarSize}
                  Side="buy"
                />
              </OverlayTrigger>
            );
          })}
        </Table2>
      </Container>
    </CardView>
  );
};

export default OrderBook;
